.location-img {
    position: relative;
}
.location-text {
    position: absolute;
    bottom: 0;
    padding: 30px;
}
.location-head h3 {
    font-size: 24px;
    color: #FFFFFF;
    font-family: 'hasnon-bold';
    margin: 0;
}
.location-head p {
    font-size: 11px;
    color: #FFFFFF;
    font-family: 'poppins-regular';
    width: 72%;
}
.location-img label {
    font-size: 14px;
    font-family: 'poppins-meduim';
    color: #ffffff !important;
}
.for-direct a {
    margin-top: 0px;
}
section.location-sec {
    padding-bottom: 110px;
}
.location-head h3 span img {
    width: 6%;
    position: relative;
    bottom: 3px;
}

@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
    .location-head p {
        font-size: 11px;
        width: 88%;
    }
    .three-links {
        left: -105px;
    }
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
    .for-direct {
        width: 90%;
    }
    .location-head h3 {
        font-size: 15px;
    }
    .location-head p {
        width: 100%;
    }
    .location-head span img {
        width: 10%;
    }
}

@media (max-width: 820px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
    .location-text {
        padding: 30px 10px;
    }
}