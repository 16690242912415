.faq-all .accordion-button {
    color: #ffffff;
    background-color: var(--third-color);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    font-size: 18px;
    font-family: "poppins-semibold";
    padding: 1px;
    border-radius: unset;
}

.faq-all .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    position: absolute;
    left: 0;
    display: none;
}

.faq-all .accordion-button h5 {
    font-size: 16px;
    /* color: var(--fourth-color); */
    font-family: "poppins-semibold";
    padding: 0;
    padding-left: 20px;
}

.faq-all .accordion-item span {
    padding: 0px 30px;
    background-color: var(--third-color);
    color: #ffffff;
    border-right: 1px solid #ffffff45;
}

.faq-all .accordion-button {

    padding: 0px;
}

.faq-all .accord-para p {
    font-size: 12px;
    color: #00000069;
    font-family: 'poppins-regular';
}

section.faq-sec {
    padding: 100px;
    padding-top: 0px;
}

.faq-all button.accordion-button[aria-expanded="false"] i::before {
    content: '\f068' !important;
}

@media (max-width: 1366px) {
    
}

@media (max-width: 1280px) {}

@media (max-width: 1100px) {
    section.faq-sec {
        padding: 80px;
        padding-top: 0px;
    }
}

@media (max-width: 1024px) {
    .content-wrapper p {
        width: 85%;
    }
}

@media (max-width: 991px) {
    .content-wrapper p {
        width: 100%;
    }

    .content-wrapper p {
        font-size: 12px;
    }
}

@media (max-width: 820px) {
    .content-wrapper h2 {
        font-size: 25px;
    }

}

@media (max-width: 768px) {
    section.faq-sec {
        padding: 80px 0px;
        padding-top: 0px;
    }

    .three-links {
        left: -84px;
    }
}

@media (max-width: 575px) {
    .faq-all .accordion-button:not(.collapsed) h5 {
        font-size: 9px;
    }

    .faq-all .accordion-button h5 {
        font-size: 12px;
    }

    .content-wrapper h2 {
        font-size: 12px;
    }

    .content-wrapper p {
        font-size: 8px;
    }

    .faq-all .accordion-item span {
        padding: 0px 20px;
    }
}