.about-second-img {
    text-align: center;
}
.whole-second-box {
    position: relative;
}
.about-small-box h5 {
    font-size: 16px;
    color: var(--fourth-color);
    font-family: 'poppins-bold';
    margin: 0;
}
.about-small-box p {
    font-size: 12px;
    font-family: 'poppins-regular';
    margin: 0;
    font-style: italic;
}
.about-small-box {
    border: 1px solid #DCDCDC;
    padding: 9px 16px;
    width: 50%;
    position: absolute;
    bottom: -90px;
    right: 0;
    background-color: #ffffffff;
}
section.banner-about-sec {
    padding-top: 0px;
}
.about-third-img img {
    width: 100%;
}

.spin-second-sec .row {
    background-image: url(../img/About/about2.webp) !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 334px;
    padding: 50px;
}

.about-sec-first-ban {
    padding: 0 !important;
}
.spin-second-sec {
    padding: 60px 0px;
    padding-bottom: 0px;
}
.spin-second-sec {
    padding-bottom: 0px;
    padding-top: 100px;
}
.about-contact-btn {
    font-size: 11px;
    width: 18%;
}
.spin-second-div p {
    width: 56%;
    padding-top: 20px !important;
}
.spin-second-div h2 {
    line-height: 23px;
}
.spin-second-div h5 {
    padding-bottom: 7px;
}
.about-third-sec {
    padding-bottom: 90px !important;
}
.faq-all .accordion-button:not(.collapsed) h5 {
    color: #ffffff;
}
.about-second-sec {
    background-color: #f9f9f9;
}
.spin-second-div p {
    font-size: 9px;
    font-family: 'poppins-regular';
}
.about-small-box h5 span {
    font-size: 12px;
    font-family: 'poppins-regular';
}
.inner-about-para-color p {
    color: #707070;
    font-family: 'poppins-regular';
}
.about-contact-btn a {
    letter-spacing: 2px;
    font-size: 15px;
}
.home-about-banner-responsive p {
    width: 100%;
}

@media (max-width: 1600px) {
    .login-memeber-ul li::before {
        width: 281px;
   
    }
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 1024px) {
    .terms-about-policy a {
        font-size: 11px;
       
    }
    section.spin-sec .row {
       
        height: 294px;
        padding: 20px;
    }
}
@media (max-width: 991px) {
    .about-third-sec {
        margin-top: 50px;
    }
    .about-contact-btn {
        font-size: 13px;
        width: 32%;
    }
    section.spin-sec .row {
        height: 294px;
        padding: 30px;
    }
}

@media (max-width: 820px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
    .spin-second-div p {
        width: 100%;
        padding-top: 20px !important;
    }
    .about-banner-text {
        margin-top: 20px;
    }
    section.spin-sec .row {
        height: 300px;
        padding: 20px;
    }
    .about-contact-btn {
        font-size: 12px;
        width: 72%;
    }
    .spin-second-sec {
        padding-bottom: 0px;
        padding-top: 20px;
    }
    .about-small-box {
        padding: 7px 16px;
        width: 90%;
        position: absolute;
        bottom: -50px;
   
    }
    .about-small-box p {
        font-size: 9px;
   
    }
    section.spin-sec .row {
        height: 300px;
        padding: 7px;
    }
}

.about-small-box::before {
    content: url('../img//abouticon.png');
    position: absolute;
    right: -23px;
    top: -27px;
}
.btn-for-about-ban a {
    margin-top: 15px;
}