.pro-text p {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: 'poppins-semibold';
    margin: 0;
}

.pro-price p {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: 'poppins-bold';
  
}

.pro-first-dv {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #dcdcdc66;
    align-items: center;
}

.pro-img {
    display: flex;
    align-items: center;
}

.for-over-flow {
    overflow-y: scroll;
    height: 200px;
    padding: 0px 30px;
    position: relative;
    right: 10px;
}
.billin-dv h4 {
    font-size: 22px;
    color: var(--fourth-color);
    font-family: 'hasnon-bold';
}

.billin-dv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.billin-dv p {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: 'poppins-regular';
}

.billin-dv p a {
    color: var(--fourth-color);
    font-family: 'poppins-semibold';
    text-decoration: unset;
}

.all-checkout label {
    font-size: 12px;
    font-family: 'poppins-meduim';
    color: var(--second-color);
    padding-bottom: 2px;
}

.all-checkout select.form-select.form-select-lg.mb-3 {
    border-radius: unset;
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
}

.check-out-input input {
    background-color: #ffffff !important;
}

.product-detail-all {
    background-color: #F9F9F9;
    padding-top: 15px;
    border: 1px solid #DCDCDC;
    border-radius: 5px 5px 0px 0px;
}

.product-detail-all .product-head {
    padding: 0px 30px;
}

.product-detail-all .all-total .total.order-hotel-all {
    padding: 11px 20px !important;
}

.product-detail-all .all-total .total.order-hotel-all p {
    margin: 0px;
}

.product-detail-all .all-total .total {
    padding: 0px 17px;
}

.product-head h5 {
    font-size: 16px;
    font-family: 'hasnon-bold';
    padding-bottom: 30px;
    color: var(--fourth-color);
}

.summary-dv h4 {
    font-size: 20px;
    font-family: 'hasnon-bold';
    color: var(--fourth-color);
    padding-bottom: 20px;
}

.pament-details h5 {
    font-size: 16px;
    font-family: 'hasnon-bold';
    color: var(--fourth-color);
    border-bottom: 1px solid #dcdcdc85;
    padding: 0px 30px;
}

.total {
    display: flex;
    justify-content: space-between;
}

.for-total-green {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: 'poppins-bold';

    min-width: 63px;
}

.pament-details {
    padding-top: 50px;
    padding-bottom: 30px;
}
.form-dv-input.check-out-input input::placeholder {
    color: #000000;
    font-family: 'poppins-meduim';
}
/* .for-over-flow::-webkit-scrollbar {
    width: 5px;
    background-color:  transparent;
}

.for-over-flow::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
}

.for-over-flow::-webkit-scrollbar-thumb {

    outline: 1px solid slategrey;
} */
.for-over-flow::-webkit-scrollbar {
    width: .3em;
}
.cart-btn a {
    margin-top: 10px;
}
.for-over-flow::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.for-over-flow::-webkit-scrollbar-thumb {
    background-color: var(--third-color);
    /* outline: 1px solid slategrey; */
}

.check-out-sec {
    padding-bottom: 100px;
}

.all-checkout {
    width: 90%;
}

.check-out-input {
    padding: 10px 0px;
}

.ordr-total {
    display: flex;
    justify-content: space-between;
    background-color: var(--third-color);
    padding: 15px 43px;
}

.ordr-total p {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.ordr-total p {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
}

.check-box-row {
    margin-top: 40px;
    border-top: 1px solid #70707042;
    display: flex;
    align-items: center;
}

.checkout-btns a {
    margin: 0;
}

.checkout-btns {
    float: right;
    margin-top: 30px;
}

.check-and {
    padding-top: 30px;
}

.check-box-row .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 6px;
    width: 6px;
    height: 10px;
    border: solid #d55302;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.memeber-check a {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.form-dv-style-select label {
    padding-bottom: 10px;
}

.check-out-input input::placeholder {
    font-size: 12px;
    text-align: end;
    font-family: 'poppins-meduim';
    color: #000000;
}

.check-out-input input::placeholder {
    font-size: 13px;
    color: #000000;
    font-family: 'poppins-semibold';
}
.all-total {
    padding: 0px 20px;
}

@media (max-width: 1280px) {}

@media (max-width: 1100px) {}

@media (max-width: 1024px) {}

@media (max-width: 991px) {}

@media (max-width: 820px) {}

@media (max-width: 768px) {}

@media (max-width: 575px) {
    .all-checkout {
        width: 100%;
    }

    .billin-dv p {
        font-size: 9px;

    }

    .content-wrapper {
        padding-bottom: 0px;
    }

    .billin-dv h4 {
        font-size: 20px;
        line-height: 20px;
    }
    .all-total {
        padding: 0px 0px;
    }
    .for-over-flow {
       
        padding: 0px 0px;
    
    }
    .ordr-total {
        padding: 15px 24px;
    }
    .check-and label {
        font-size: 8px;
    }
    .checkout-btns {
        width: 100%;
    }
}

.checkout-btn a {
    text-transform: uppercase;
}