.lounge-img img {
    margin: 10px;
}
.lounge-first-img {
    height: 515px;
    object-fit: cover;
    border-radius: 4px;
}
.lounge-img {
    position: relative;
}
.para-check p span {
    font-size: 9px;
    background-color: #D54302;
    color: #ffffff;
    padding: 4px 6px;
    border-radius: 4px;
    margin-right: 10px;
    margin-left: 10px;
}
.para-check p {
    font-size: 13px;
    color: var(--fourth-color);
    font-family: 'poppins-semibold';
    margin: 0;
}
.para-check {
    padding: 8px 0px;
}

section.check-para-sec .row {
    display: flex;
    align-items: end;
}
.call-us-now {
    position: absolute;
    bottom: 0;
}
section.check-para-sec {
    padding-bottom: 100px;
}
.lounge-header h5 {
    font-size: 24px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
    font-style: italic;
}
.lounge-header p {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
}
.lounge-header {
    text-align: center;
}
.for-some-extra-css {
    text-align: center;
    padding-top: 110px;
}
.for-some-extra-css h2 {
    line-height: 37px;
    padding-top: 18px;
}

@media (max-width: 1366px) {
    .lounge-first-img {
        height: 499px;
        
    }
}
@media (max-width: 1280px) {
    .lounge-first-img {
        height: 499px;
        
    }
}
@media (max-width: 1100px) {
    .lounge-first-img {
        height: 420px;
    }
    .para-check p {
        font-size: 8px;
       
    }
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
    .lounge-first-img {
        height: 316px;
    }
    .call-us-now img {
        width: 50%;
    }
    .para-check p {
        font-size: 6px;
    }
}

@media (max-width: 820px) {
}
@media (max-width: 768px) {
}
@media (max-width: 575px) {
    .lounge-img img {
        margin: 10px 0px;
        width: 100%;
    }
    .para-check p {
        font-size: 11px;
    }
    .for-some-extra-css {
        text-align: center;
        padding-top: 20px;
    }
    .para-check p {
        font-size: 7px !important;
    }
    .para-check p span {
        font-size: 7px;
        padding: 1px 3px;
       
    }
    .lounge-header p {
        font-size: 11px;
       
    }
    .lounge-header h5 {
        font-size: 22px;
    }
    .content-wrapper h5 {
        letter-spacing: 0px !important;
    }
    .for-some-extra-css h2 {
        padding-top: 0px;
    }
}