.my-profile-head-and-btn {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.my-profile-head-and-btn h3 {
    font-size: 25px;
    font-family: "hasnon-bold";
    color: var(--second-color);
    text-transform: uppercase;
    margin: 0;
}

section.my-profile-sec {
    padding: 140px 0px;
    position: relative;
}

.profile-edit-btn {
    width: 25%;
}

.names-phones.only-for-text-center span a {
    font-size: 10px;
    color: var(--third-color);
    font-family: "poppins-bold";
    position: relative;
    bottom: 7px;
    left: 12px;
}

.names-phones p {
    font-size: 12px;
    color: #2a263d;
    font-family: "poppins-semibold";
}

.names-phones h5 {
    font-size: 15px;
    color: #2a263d;
    font-family: "poppins-meduim";
    margin: 0;
}

.names-phones {
    padding: 10px 0px;
}
section.member-sec.my-profile-sec .row {
    display: flex;
    align-items: center;
}

/* Begin of Edit profile Css */
.edit-profile-input input {
    width: 100%;
    border: 1px solid #c3cad9;
    padding: 6px 12px;
    outline: unset;
}

.edit-profile-input label {
    font-size: 14px;
    color: var(--fourth-color);
    font-family: "poppins-meduim";
    /* padding: 10px 0px; */
    padding-bottom: 5px;
    padding-top: 20px;
}

.change-password-input input {
    width: 40%;
    padding: 7px;
    border: 1px solid #c3cad9;
}

/* ========================= */

/* Begin of Change Password Css */
.change-password-btn {
    width: 100%;
    margin-top: 30px;
}

.change-password-input input {
    width: 49%;
    padding: 7px;
    border: 1px solid #c3cad9;
}

.change-password-form label {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: "poppins-meduim";
    padding: 10px 0px;
    padding-bottom: 0px;
}

.my-orders-sec td {
    text-align: center;
    white-space: nowrap;
    border-top: 1px solid #00000029;
    border-bottom: 1px solid #00000029;
    padding: 4px 0px;
}
.table-all p {
    font-size: 16px;
    font-family: "poppins-regular";
    color: var(--second-color);
    margin: 0;
    white-space: nowrap;
}
.table-all tr {
    border: 1px solid #7070702e;
    background: #ffffff;
}

.table-all th {
    font-size: 14px;
    color: var(--second-fourth);
    font-family: "poppins-bold";
    padding-bottom: 15px;
    text-align: center;
}

.table-all td a {
    font-size: 12px;
    color: #d55302;
    font-family: "poppins-bold";
    text-transform: uppercase;
}



.change-password-btn a {
    font-size: 15px;
}

/* ========================== */

/* Begin of View order details */
.dv-for-flex {
    display: flex;
    align-items: center;
    padding: 15px 0px;
    justify-content: space-between;
}

.two-stuff {
    display: flex;
    align-items: center;
}

.order-views {
    background-color: #fff;
    border: 1px solid #00000017;
    border-bottom: initial;
    padding: 30px 40px;
    position: relative;
    width: 64%;
    padding-right: 80px;
}

.for-dv-text h5 {
    font-size: 14px;
    font-family: "poppins-semibold";
    margin: 0;
}

.for-dv-text p {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: "poppins-regular";
    margin: 0;
}

.for-price h4 {
    font-size: 16px;
    font-family: "poppins-semibold";
    color: var(--third-color);
    margin: 0;
}
.order-views-bottom .dv-for-flex {
    display: flex;
    align-items: center;
    padding: 4px 0px;
    justify-content: space-between;
}
.order-views-bottom .two-stuff {
    display: flex;
    align-items: center;
    flex: 0 0 33%;
}
/* .order-views-bottom .for-price {
    position: unset;
    flex: 0 0 33%;
} */
.order-views-bottom .for-price {
    position: initial;
    /* flex: 0 0 16%; */
    min-width: 104px;
}
.order-views h3 {
    font-size: 16px;
    color: var(--fourth-color);
    font-family: hasnon-bold;
    margin: 0;
}

/* .for-price {
    position: absolute;
    right: 150px;
    top: unset;
} */

.for-dv-text {
    padding-left: 12px;
}

.for-borderss {
    border-top: 1px solid #dcdcdc59;
    border-bottom: 1px solid #dcdcdc66;
}

.order-views-bottom {
    border-bottom: 1px solid #dcdcdc;
    border-top: initial;
    padding-right: 0!important;
    padding-top: 0;
    padding-right: 36px !important;
}

.text-font-chnage h5 {
    font-family: "poppins-regular";
}

.order-views-bottom h3 {
    border-bottom: 1px solid #dcdcdc;
}

/* ========================== */

/* Begin of My address Css */
/* Saved Address Css Start Here */
.checkout .saved-address label.container .select-address .selection {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 20px;
    opacity: 0;
}

.checkout .saved-address label.container .select-address .selection i.fa.fa-check {
    background: #0eb702;
    color: #fff;
    font-size: 14px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
}

.checkout .saved-address label.container input:checked~span.checkmark .select-address .selection {
    opacity: 1;
}

.checkout .saved-address .title {
    padding-bottom: 25px;
}

.checkout .saved-address .title h5 {
    font-size: 15px;
}

/* The container */
.checkout .saved-address .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.checkout .saved-address .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkout .saved-address span.checkmark {
    position: unset;
}

.checkout .saved-address label.container {
    padding: 0;
}

.checkout .saved-address label.container .select-address {
    border: 1px solid #b5b5b56b;
    padding: 1px 30px;
    position: relative;
    overflow: hidden;
    background: #ffffff;
    border-radius: unset !important;
}
.checkout .saved-address label.container input:checked~span.checkmark .select-address {
    border-color: var(--third-color);
}

.checkout .saved-address .select-address .radio-btn span {
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
    left: 0;
    border-radius: 50%;
    border: 2px solid #1b390f;
    box-shadow: 0px 0px 0px 1px #1b390f;
    top: 10px;
    margin: 20px 20px;
}

.checkout .saved-address label.container input:checked~span.checkmark .select-address .radio-btn span {
    background: var(--third-color);
    border: 2px solid #ffffff;
}

.checkout .saved-address .select-address {
    position: relative;
}

.checkout .saved-address .select-address .address-detail {
    padding-left: 20px;
    margin-left: 30px;
    border-left: 1px solid #7070701a;
    margin-top: 7px;
}
.checkout .saved-address .select-address .address-detail .top {
    display: flex;
}

.checkout .saved-address .select-address .address-detail h5 {
    width: 100%;
    padding: 0px 0;
    margin: 4px 0px;
}
.checkout .saved-address .select-address .address-detail h5 span.property {
    font-size: 14px;
    color: var(--fourth-color);
    font-weight: 500;
    font-family: poppins-bold;
}

.checkout .saved-address .select-address .address-detail h5 span.value {
    margin-left: 20px;
    font-weight: 400;
    color: var(--second-color);
    font-size: 12px;
    font-family: poppins-regular;
}

.checkout .saved-address .select-address .address-detail h5.last span.value {
    margin-left: 33px;
}

.checkout .saved-address .select-address .button-group {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.checkout .saved-address .select-address .address-detail button.btn {
    background: #c9c9c945;
    color: #7d7d7d;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 1;
    border-radius: 0;
    padding: 6px 20px;
}

.checkout .saved-address .select-address .address-detail button.lnk {
    background: transparent;
    border: 0;
    font-size: 15px;
    color: #1473e6;
    text-decoration: underline;
    margin-left: 10px;
}

.checkout .add-new-address {
    padding-top: 20px;
    position: relative;
}

.checkout .form-group {
    margin-bottom: 15px;
}

.checkout .add-new-address label.container {
    padding: 0 25px 20px;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
}

.checkout .add-new-address label.container span.checkmark {
    background: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #f35d63;
    top: 24.5px;
}

.checkout .add-new-address label.container input {
    position: absolute;
    left: 0px;
    opacity: 0;
}

.checkout .add-new-address label.container input:checked~span.checkmark {
    background: #fb7b1c;
}

.checkout .add-new-address .form-group label {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
}

.checkout .add-new-address .form-group .form-control {
    border-radius: 0;
    height: 50px;
    border-color: #fff;
    border-radius: 10px;
    background: transparent;
    color: #fff;
    font-weight: 400;
}

.checkout .add-new-address .checkbox {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.checkout .add-new-address .checkbox .form-control {
    width: auto;
}

.checkout .add-new-address .checkbox label {
    margin-bottom: 0;
    color: #636060;
    font-size: 15px;
    padding-left: 15px;
}

.address-edit-btn a {
    font-size: 12px;
    color: var(--first-color);
    font-family: "poppins-bold";
    text-transform: uppercase;
}

.my-all-address {
    width: 84%;
}

.for-some-space {
    padding-bottom: 30px;
}
.address-second {
    padding-bottom: 50px;
}
/* ===================================== */

/* Begin of Addnewaddress Css */
.add-address-input input {
    width: 100%;
    height: 47px;
    border-radius: unset;
    border: 1px solid #c3cad9;
    padding: 0px 20px;
}

.add-address-input input::placeholder {
    font-size: 14px;
    color: #183b5640;
    font-family: "poppins-regular";
}

.add-address-input label {
    font-size: 12px;
    color: var(--fourth-color);
    padding: 10px 0px;
    font-family: "poppins-meduim";
}

/* =========================== */

/* Begin of Sidebar Css */
.siderbar-tabs {
    background-color: var(--third-color);
    border-radius: 8px 8px 0px 8px;
    position: relative;
}

.sidebar-ul {
    list-style: none;
    padding: 0;
    padding-bottom: 50px;
    padding-top: 70px;
}
.sidebar-ul li a {
    font-size: 12px;
    color: #ffffff;
    font-family: 'poppins-regular';
    text-decoration: unset;
    z-index: 1;
}
.sidebar-ul li {
    padding: 12px 40px;
    border: 1px solid #ffffff0f;
}

.sidebar-img img {
    width: 100px;
    height: 100px;
    border: 6px solid #ffffff;
    border-radius: 80px;
    object-fit: cover;
}

.sidebar-img {
    position: absolute;
    top: -51px;
    display: flex;
    right: 0;
    left: 0;
    left: 0 !important;
    right: 0;
    justify-content: center;
}

.all-sidebar-stuff {
    position: relative;
}

.profile-edit-btn a {
    margin-top: 0px;
}
.profile-all {
    padding: 0px 50px;
}
.my-profile-sec::before {
    content: "";
    background: #f9f9f9;
    position: absolute;
    width: 75%;
    height: 430px;
    right: 80px;
    bottom: 158px;
    border-radius: 5px;
    z-index: -1;
}

.side-abr-icon {
    position: absolute;
    top: 0;
    right: 84px;
}

.side-abr-icon i {
    background-color: var(--first-color);
    padding: 6px;
    border-radius: 60px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 10px;
}

.my-order-view-detail-sec::before {
    content: "";
    background: #f9f9f9;
    position: absolute;
    width: 85.5%;
    height: 890px !important;
    right: 80px;
    bottom: 41px;
    border-radius: 5px;
    z-index: -1;
}

.all-border-and-back {
    border: 1px solid #dcdcdc;
    background-color: #ffffff;
}

.for-no-paddng {
    padding-left: 0px;
}

.edit-profile-input input::placeholder {
    opacity: 0.6;
}

.for-no-margin a {
    margin-top: 10px;
}

.my-orders-sec table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
}
.for-left-border {
    border-left: 1px solid #00000021;
}

.for-right-border {
    border-right: 1px solid #0000002e;
}

.login-first-dv {
    background-image: url(../img//Login/newmember.png);
    padding-top: 32px;
    box-shadow: 0px;
    height: 118px;
}

.all-login-membership {
    box-shadow: 0px 0px 0px 0px;
}
.profile-edit-btn a {
    padding: 6px;
}
.member-sec::before {
    content: "";
    background: #f9f9f9;
    position: absolute;
    width: 75%;
    height: 450px;
    right: 80px;
    bottom: 123px;
    border-radius: 5px;
    z-index: -1;
}
.login-member-text h2 {
    line-height: 66px;
}
/* ======================= */
/* table {
    border-collapse: separate;
    border-spacing: 0 15px;
  } */
  @media (max-width: 1600px) {
    .side-abr-icon {
   
        right: 106px;
    }
    .order-views {
        width: 64% ;
    }
  }

@media (max-width: 1366px) {
    .change-password-btn a {
        font-size: 11px;
        padding: 7px;
        margin: 0;
    }
    .member-btn-login a {
        font-size: 12px;
       
    }
}

@media (max-width: 1280px) {
    .change-password-btn a {
        font-size: 11px;
        padding: 7px;
    }

    .my-order-view-detail-sec:before {
        width: 88%;
    }
    .member-btn-login a {
        font-size: 12px;
        
    }
    .profile-edit-btn a {
        font-size: 13px;
    }
    .my-order-view-detail-sec::before {
        right: 73px;
     
    }
}

@media (max-width: 1100px) {
    .names-phones p {
        font-size: 10px;
    }

    .profile-all {
        padding: 0px 20px;
    }
    .member-sec::before {
        width: 81%;
        right: 30px;
      
    }
    .login-memeber-ul {
        padding: 0px 22px;
    }
    .login-member-text h2 {
        font-size: 37px;
    }
    .member-btn-login a {
        font-size: 11px;
    }
}

@media (max-width: 1024px) {}

@media (max-width: 991px) {
    .sidebar-ul li a {
        font-size: 11px;
    }

    .names-phones.only-for-text-center span a {
        font-size: 9px;
    }

    .sidebar-img {
        left: 27px;
        bottom: 60px;
    }

    .sidebar-img img {
        width: 100px;
        height: 100px;
    }

    section.my-profile-sec {
        padding: 60px 0px;
    }

    .profile-edit-btn {
        width: 35%;
    }
}

@media (max-width: 820px) {}

@media (max-width: 768px) {}

@media (max-width: 575px) {
    .names-phones h5 {
        font-size: 18px;
    }

    .names-phones p {
        font-size: 13px;
    }

    .my-all-address {
        width: 100%;
    }
    .my-profile-head-and-btn {
        display: block;
    
    }
    .profile-edit-btn {
        width: 100%;
      }
      .names-phones p {
    font-size: 13px;
}
.my-profile-sec::before {
    opacity: 0;
}
.change-password-input input {
    width: 100%;
   
}
.my-profile-head-and-btn h3 {
    font-size: 20px;
    text-align: center;
}
.change-password-btn a {
    font-size: 16px;
}
.sidebar-ul {

    z-index: 1;
    position: relative;
}
.table-all {
    overflow: scroll;
}
.order-views {
    width: 100%;
    padding: 30px 10px;
    
}
.for-dv-text h5 {
    font-size: 10px;
}
.stuff-img img {
    width: 50px;
}
.for-price h4 {
    font-size: 10px;
}
.order-views-bottom .for-price {
    flex: initial;
}
.for-no-paddng {
    padding-right: 0;
}
.member-btn-login {
    width: 100%;
}
.become-member-ul {
    border: 1px solid #dcdcdc;
}
.login-memeber-ul li::before {
    width: 0px !important;
}
.all-border-and-back {
    border: unset;

}
.login-member-col {
    text-align: center;
}
.side-abr-icon {
    right: 107px;
}
.checkout .saved-address .select-address .address-detail h5 span.property {
    font-size: 11px;

}
.checkout .saved-address .select-address .address-detail h5 span.value {

    font-size: 9px;
}
.address-edit-btn a {
    font-size: 9px;
}
.checkout .saved-address label.container .select-address {
    padding: 1px 10px;
   
}
.checkout .saved-address label.container .select-address .selection i.fa.fa-check {
  
    font-size: 11px;
    width: 15px;
    height: 15px;

    line-height: 18px;

}
}