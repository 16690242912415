.terms-all p {
    font-size: 12px;
    color: var(--fourth-color);
    font-family: "poppins-regular";
    line-height: 1.6;
}

.terms-conditons-sec {
    padding-bottom: 90px;
}

.terms-all h2 {
    font-size: 32px;
    font-family: 'hasnon-bold';
    color: var(--second-color);
}

.terms-conditons-sec ul li {
    font-family: "poppins-regular";
    font-size: 14px;
}
.terms-all ul {
    padding: 0px 19px;
}
.terms-all ul li {
    font-size: 12px;
    font-family: 'poppins-regular';
    line-height: 32px;
}

@media (max-width: 575px) {
    .terms-all h2 {
        font-size: 15px;
        margin: 0;
    }.terms-all p {
        font-size: 10px;
       
    }
    
}