.event-img {
  position: relative;
}
.event-text {
  position: absolute;
  bottom: 0;
  padding: 40px;
  padding-bottom: 24px;
}
.event-text h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "poppins-bold";
  margin: 0;
}
.event-text h5 {
  font-size: 10px;
  color: #ffffff;
  font-family: "poppins-regular";
  margin: 0;
}
.event-text p {
  font-size: 9px;
  color: #ffffff;
  font-family: "poppins-regular";
  margin: 0;
  padding-bottom: 14px;
}
.event-text a {
  font-size: 14px;
  color: #ffffff;
  font-family: poppins-meduim;
  text-decoration: unset;
}
.event-img img {
  height: auto;
}
.event-text span {
  padding-right: 10px;
}
.special-tag p {
  font-size: 13px;
  background-color: #d55302;
  color: #ffffff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 1px 15px;
  margin: 0;
}
.special-tag {
  position: absolute;
  top: 26px;
  right: 26px;
}
.member-t-img img {
  width: 75%;
  margin: 0 auto;
}
.members-t h3 {
  font-size: 18px;
  font-family: "poppins-bold";
  line-height: 1px;
}
.members-t h5 {
  font-size: 10px;
  font-family: "poppins-regular";
  color: var(--third-color);
  margin: 0;
}
.members-t p {
  font-size: 8px;
  font-family: "poppins-regular";
  color: #000000a1;
  margin: 0;
  margin-bottom: 3px;
  /* margin: 0; */
}
.members-t a {
  font-size: 14px;
  color: var(--first-color);
  font-family: "poppins-meduim";
  text-decoration: unset;
}
.members-t h5 span {
  padding-right: 10px;
}

.member-t-tag p {
  font-size: 10px;
  background-color: #d55302;
  color: #ffffff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 1px 15px;
  margin: 0;
}
.member-t-tag {
  position: absolute;
  top: 8px;
  right: 8px;
}
.member-t-img {
  position: relative;
  text-align: end;
}
.for-align-setting {
  display: flex;
  align-items: end;
  padding: 6px;
  border-bottom: 1px solid #6b6a6a0d;
}
.for-background-color {
  background-color: #fcfcfc;
}
.for-adjust {
  width: 98%;
}
.mem-t h3 {
  font-size: 15px;
  color: var(--second-color);
  font-family: "poppins-bold";
  line-height: 1px;
}
.mem-t p {
  font-size: 10px;
  color: #00000070;
  margin-bottom: 12px;
}
.mem-t h5 {
  font-size: 10px;
  color: var(--thiid-color);
  font-family: "poppins-regular";
}
.mem-t a {
  font-size: 14px;
  color: var(--first-color);
  font-family: "poppins-meduim";
  text-decoration: unset;
}
.mem-t {
  padding: 24px 4px;
}
.event-special-all {
  position: relative;
  padding-top: 0px;
  background-color: #fcfcfc;
  border: 1px solid #c9c9c926;
  border-radius: 10px;
  padding: 12px;
  margin-top: 30px;
}

.special-event-tag p {
  font-size: 9px;
  background-color: #d55302;
  color: #ffffff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 1px 15px;
  margin: 0;
}
.special-event-tag {
  position: absolute;
  top: 27px;
  right: 24px;
}
.mem-t h5 span {
  padding-right: 7px;
}
section.event-special-sec {
  padding-bottom: 90px;
}

/* Begin of Event Deatil Css */
.events-img-all {
  position: relative;
}
.event-para p {
  font-size: 14px;
  color: #0000006e;
  font-family: "poppins-regular";
}
.event-para {
  margin-top: 50px;
}
section.Event-Detail-Section {
  padding: 100px 0px;
}
/* ================ */
@media (max-width: 1440px) {
  .three-links {
    top: 340px;
}
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1100px) {
  .nav-home ul li a {
    font-size: 9px;
  }
  .mem-t h3 {
    font-size: 12px;
    line-height: 10px;
  }
  .members-t h3 {
    font-size: 16px;
    line-height: 1px;
  }
  .profile-text h5 {
    font-size: 8px;
  }
  .profile-text h4 {
    font-size: 7px;
  }
  .content-wrapper h2 {
    font-size: 30px;
  }
  .content-wrapper p {
    width: 65%;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
  .member-t-img img {
    width: 100%;
  }
  .members-t h3 {
    font-size: 11px;
    line-height: 16px;
  }
  .for-align-setting {
    display: flex;
    align-items: flex-start;
  }
  .for-adjust {
    width: 100%;
    height: 340px !important;
    object-fit: cover;
  }
  .event-text {
    padding: 15px;
    padding-bottom: 24px;
  }
  .event-text h3 {
    font-size: 17px;
  }
  .mem-t h3 {
    font-size: 12px;
    line-height: 15px;
  }
  .mem-t a {
    font-size: 12px;
  }
  .special-event-tag p {
    font-size: 7px;

    padding: 1px 8px;
  }
  .special-event-tag {
    top: 22px;
    right: 16px;
  }
  .content-wrapper p {
    width: 85%;
}
}

@media (max-width: 820px) {
}
@media (max-width: 768px) {
    .three-links {
        left: -105px;
    }
    .three-links a {
        font-size: 10px;
       
    }
    .three-links {
        left: -83px;
    }
}
@media (max-width: 575px) {
    section.pageheader {
        text-align: center;
        padding: 30px 0px;
    }
    .content-wrapper h2 {
        font-size: 16px;
        line-height: 33px;
    }
    .content-wrapper p {
        width: 100%;
    }
    .content-wrapper p {
        font-size: 10px;
    }
    .event-special-all {
        margin-top: 0px;
        padding: 0px;
    }
    .event-text {
        bottom: 0px;
    }
    .three-links {
        opacity: 0;
    }
    .special-event-tag {
        top: 17px;
        right: 17px;
    }
    .special-tag p {
        font-size: 8px;
    
    }
    .special-tag {
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .members-t {
        padding: 15px 0px;
    }
    section.event-special-sec {
        padding-bottom: 30px;
    }
    .content-wrapper h5 {
        font-size: 10px;
    }
}
